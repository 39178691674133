.CPWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 20;
}

#modal-background {
  position: absolute;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 15;
  width: 100vw;
  background-color: white;
  opacity: 0.4;
  filter: brightness(50%);
}

.CPContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* width: 82%; */
  opacity: 1;
  background-color: white;
  border-radius: 5px;
  position: relative;
  padding: 0.7rem 0.9rem;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.8em;
  word-spacing: 5px;
  z-index: 20;
}

.conf-accept {
  color: #67833e;
  margin-left: 5%;
  cursor: pointer;
  font-size: 12px;
}

.conf-cancel {
  color: #7a7a7a;
  cursor: pointer;
  font-size: 12px;
}

.conf-question {
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 3%;
}

@media screen and (max-device-width: 500px) {
  .CPContainer {
    width: 80%;
  }
}

@media screen and (min-device-width: 500px) {
  .CPContainer {
    width: 335px;
  }
}
