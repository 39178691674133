@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-user-select: none;
          user-select: none; 
  cursor: default;
}
/* body::-webkit-scrollbar {
  display: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="text"] {
  -webkit-appearance: none;
  appearance: none;
}

input[type="number"] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

input[type="tel"] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

.Toastify__toast--success {
  background-color: #67833e !important;
  font-family: Poppins;
}

/**
 Adds Poppins Regular,Light,Medium   
*/

p {
  margin: 0;
}

.countryCode {
  color: #4d4d4d;
  font-size: 1rem !important;
  font-weight: 400;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

input[type="text"],
input[type="number"] {
  font-size: 1rem;
}

/* Theme Values */
:root {
  --color-primary-green: #67833e;
  --color-primary-info: #3980ff;
  --color-primary-alert: #fb6a6a;

  --color-bg: #e5e5e5;

  --color-grey-1: #e3e3e3;
  --color-grey-2: #aaaaaa;
  --color-grey-3: #ababab;
  --color-grey-4: #b8b8b8;
  --color-grey-5: #939393;
  --color-grey-6: #8d8d8d;
  --color-grey-7: #868686;
  --color-grey-8: #808080;
  --color-grey-9: #797979;
  --color-grey-10: #6a6a6a;
  --color-grey-11: #656565;
  --color-grey-12: #5b5b5b;
  --color-grey-13: #525252;
  --color-grey-14: #3c3c3c;

  --font-size-8: 0.5rem;
  --font-size-9: 0.5625rem;
  --font-size-10: 0.625rem;
  --font-size-11: 0.6875rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-15: 0.9375rem;
  --font-size-16: 1rem;

  --font-family-poppins: Poppins;
}


.psuduoRoute {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  align-self: center;
  justify-content: center;
  /* height: 90vw; */
  /* border: groove black; */
}

@media screen and (min-device-width: 500px) {
  .psuduoRoute {
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-self: center;
    overflow-y: scroll;
    /* height: 90vw; */
    /* border: groove black; */
  }
}


@media screen and (max-device-width: 500px) {
  #innerContainer {
    /* background-color: black; */
    /* height: 100%; */
    width: 100%;
    background-color: #fff;
    overflow: scroll;
    /* overflow-y: scroll; */
    /* height: 90vw; */
    /* border: groove black; */
    position: relative;
  }
 
  ::-webkit-scrollbar {
    display: none !important ;
  }
}

@media screen and (min-device-width: 500px) {
  #innerContainer {
    /* background-color: black; */
    /* height: 100%; */
    width: 400px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: scroll;
    /* height: 100%; */
    /* border: groove black; */
    position: relative;
  }
  ::-webkit-scrollbar {
    width: 8px !important;
  }
}

.desktop{
  background-color: #fff !important;
  height: 100% !important;
  width: 100% !important;
}

#call-waiter-btn {
  font-family: Poppins;
  color: white;
  background-color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  pointer-events: all;
  z-index: 16;
  margin-right: 4rem;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* width: 8px !important; */
}

::-webkit-scrollbar-thumb {
  background-color: #b3b2b2;
  border-radius: 5px;
  /* width: 8px !important; */
}

 


@media screen and (max-device-width: 500px) {
  ::-webkit-scrollbar {
    display: none !important; 
    /* width: 10px !important; */
  } 
}
/* custom-toast.css */
.Toastify__progress-bar--success {
    background-color: #67833E !important; /* Change this to your desired progress bar color */
}

.custom-toast-container {
    background-color: #829c5c !important; /* Change this to your desired background color */
    color: #ffffff !important; /* Change text color if needed */
}

.cdp-customize {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* height: 100vh; */
  /* width: 100vw; */
  overflow-y: auto;
  overflow-x: hidden;
}

.cdp-customize::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.cdp-customize::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff; 
  border-radius: 10px;
}
 
/* Handle */
.cdp-customize::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}

/* Handle on hover */
.cdp-customize::-webkit-scrollbar-thumb:hover {
  background: grey; 
}

.custom-header {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 0 !important;
  margin-bottom: 0.85rem;
}

.customisation-back-icon {
  height: 1rem;
  padding-right: 0.7rem;
  position: fixed;
}

.ingredients-image {
  width: 14px;
  height: 11px;
}

.dishPlaceholder-custom {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 0.25rem solid white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  opacity: 1;
}

#custom-backImg {
  left: 4%;
  top: 5%;
  /* position: absolute; */
}

#custom-image {
  display: flex;
  align-self: center;
  height:10rem;
  width: 10rem;
  border-radius: 50%;
}

#custom-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: calc(var(--wWidth) * 0.039);
  line-height: 21px;
  color: #000000;
  margin-top: 1.5%;
  text-align: center;
}

#custom-heading-itemaltname {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 21px;
  color: #000000;
  margin-top: 1.5%;
  text-align: center;
}

#food-content {
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;
  margin-top: 2%;
  justify-content: space-evenly;
}

#ingredient-container {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.food-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 300;
  font-size: 0.6rem;
  font-family: Poppins;
  /* margin-right: 0.8rem; */
}

.food-content-image-container {
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.food-content-image-container img {
  height: 70%;
  width: 70%;
  /* padding: 0.5em; */
  object-fit: contain;
}

.custom-header > span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.count-button {
  border-radius: 50%;
  border: 1px solid #ababab;
  background-color: #ffffff;
}

.desc {
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
  font-size: calc(var(--wWidth) * 0.029);
  line-height: 16px;
  text-align: justify;
  margin-top: 0.8em;
}

.custom-item-elem {
  display: flex;
  flex-direction: row;
  background: white;
  border-top: 1px solid rgba(196, 196, 196, 0.2);
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  margin-bottom: 30px;
}

.custom-item-elem::-webkit-scrollbar {
  /* display: none; */
}

.Item-count {
  min-width: 28%;
  color: #292929;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: calc(var(--wWidth) * 0.037);
  padding-top: calc(var(--wWidth) * 0.021);
  padding-bottom: calc(var(--wWidth) * 0.021);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 5.3%;
  background-color: white;
  border: 1px solid #b4b4b4;
  color: #b4b4b4;
  border-radius: 5px;
  cursor: pointer;
}

.Item-count-selected {
  background-color: #67833e;
  color: white;
}

.Custom-Label {
  padding-left: 0.4rem;
}

#Custom-Save {
  background: #67833e;
  color: white;
  /* border: 0.5px solid #c1c1c1; */
  box-sizing: border-box;
  /* width: 100%; */
  /* height: 7.79%; */
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  /* border-radius: 5px; */
  line-height: 24px;
  /* position: fixed; */
  /* bottom: 0px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.custom-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 12px;
  font-size: 14px;
  padding: 1%;
}

.size {
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-inline-end: 6%;
  width: 50%;
}

.Price {
  /* width: 50%; */
  align-items: center;
  padding-inline-start: 30%;
}

.Price > span:nth-child(1) {
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  margin-top: 2.5;
}

.Price > span:nth-child(2) {
  font-style: normal;
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
}

.Price > span:nth-child(3) {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  vertical-align: text-top;
}

.extras {
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-inline-end: 6%;
  width: 50%;
}

#Customize-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.edit-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1%;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
}

.edit-value > div,
.edit-value-selected > div {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.edit-value > div span {
  font-weight: 300;
  color: #4d4d4d;
}

.edit-value-selected > div span {
  font-weight: 500;
}

.edit-value-selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 1%;
  cursor: pointer;
  width: 100%;
  /* color: #67833e; */
  margin-bottom: 1.25rem;
  font-size: 0.875rem;

  font-family: Poppins;
  font-weight: 500;

  color: #000000;
}

.money-symboll {
  font-size: 1rem !important;
  /* position: relative; */
  font-weight: 500 !important;
  margin-right: 0.6px;
  display: inline;
  margin-right: 2px;
  font-family: initial;
  line-height: 9.5px;
  top: 0px;
}

.cust-seperator {
  border-top: 1px solid rgba(196, 196, 196, 0.5);
  width: 100%;
  height: 0px;
  /* margin-top: 0.93rem; */
  margin-bottom: 0.93rem;
  display: block;
}

.Customizing-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 6.4%;
  padding-right: 6.4%;
}

.Customizing-heading > span:first-child {
  font-size: calc(var(--wWidth) * 0.037);
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  opacity: 0.7;
  /* padding-right:calc(var(--wWidth) * 0.205); */
}

.Customizing-heading > span:nth-child(2) {
  font-size: calc(var(--wWidth) * 0.037);
  opacity: 0.7;
  color: #3980ff;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.custom-check {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 21px;
  color: #000000;
}

.Custom-check {
  border: 1px solid #000000;
  box-sizing: border-box;
}

.customize-modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: calc(var(--wWidth) * 0.931);
  height: calc(var(--wHeight) * 0.425);
  border-radius: 5px;
  z-index: 3;
}

.customize-modal > p {
  font-size: calc(var(--wWidth) * 0.032);
  padding-left: calc(var(--wWidth) * 0.088);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  color: #000000;
}

.customize-modal-container {
  display: flex;
  align-items: flex-start;
  padding-top: 75%;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 5;
  box-sizing: border-box;
}

.customize-modal-container1 {
  display: flex;
  align-items: flex-start;
  padding-top: 150%;
  justify-content: center;
  position: fixed;
  background-color: rgba(216, 216, 216, 0.4);
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
}

#note-section {
  padding-left: 5.3%;
  padding-right: 5.3%;
  display: flex;
  flex-direction: column;
  padding-bottom: 15%;
  box-sizing: border-box;
}

#note-section span {
  margin-bottom: 1em;
  font-size: 0.875rem;
  color: #4d4d4d;
  font-weight: 400;
  font-family: Poppins;
}

#custom-note {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  padding: 3%;
  font-family: Poppins;
  font-size: 0.875rem;
  height: calc(var(--wHeight) * 0.097);
  margin-bottom: calc(var(--wHeight) * 0.019);
  outline: none;
  resize: none;
  overflow: auto;
}

.edit-container {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0.5625rem;
  border-top: 1px solid rgba(196, 196, 196, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Poppins;
}

.edit-container > div {
  height: auto;
}

.edit-name {
  opacity: 0.7;
  display: block;
  margin-bottom: 1.06rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.edit-name > span {
  font-size: 0.75rem;
  opacity: 0.6;
}

.required-text {
  background: rgba(254, 153, 35, 0.16);
  border: 1px solid #e57917;
  box-sizing: border-box;
  border-radius: 10px;
  color: #e57917;
  padding: 0px 10px 2px 10px;
  opacity: 1 !important;
  margin-left: auto;
}
.cust-customisation-category {
  width: 75%;
  word-break: break-all;
}

.cust-sub-head {
  /* margin-left: 5.3%; */
  margin-top: 1em;
  color: #4d4d4d;
}

.cust-options {
  /* margin-left: 5.3%; */
  margin-top: 1em;
  display: flex;
}

.cust-options div {
  display: inline-block;
  font-size: 0.875rem;
  cursor: pointer;
  color: #6a6a6a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* width: 7rem; */
  margin-right: 1rem;
  font-family: Poppins;
}

.cust-options .bottom-bar {
  background-color: white;
  height: 0.1875rem;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 0.5rem;
}

.cust-option-selected {
  color: #67833e;
}

.cust-option-selected .bottom-bar {
  background-color: #67833e;
}

.cancel-item path {
  fill: #ffffff;
}

.item-indiv-price {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding-left: 5.3%;
  padding-right: 5.3%;
  background-color: #e1efd0;
  color: #292929;
  font-size: 0.75rem;
}
.item-indiv-price > span {
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}

.option-img-radio {
  height: 20px;
  width: 20px;
}

.option-img-selected {
  height: 18px;
  width: 18px;
}

.option-img-unselected {
  height: 16px;
  width: 16px;
}

.finishbuttonContainer {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (min-device-width: 500px) {
  #Custom-Save {
    width: 100%;
  }
}

@media screen and (max-device-width: 500px) {
  #Custom-Save {
    width: 100%;
  }
}

.customisation-menu-detail-price {
  font-size: 16px;
  text-align: center;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  background-color: #fff;
  z-index: 1;
  margin-bottom: 1rem;
}

.left-arrow-customization svg {
  height: 1rem;
  margin-top: 20px;
  cursor: pointer;
}
.menu-item-ELWrappers {
  position: fixed;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 16;
}

#modals-background {
  position: fixed !important;
  height: 163vh;
  left: 0;
  top: 0;
  z-index: 16;
  width: 100%;
  background-color: white;
  opacity: 0.4;
  filter: brightness(0%);
}

.menu-item-ELContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  opacity: 1;
  background-color: white;
  border-radius: 5px;
  top: 20%;
  position: relative;
  padding: 0.9rem 0.9rem;
  font-family: Poppins;
  font-size: 0.8em;
}

.ItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  padding-bottom: 0.5em;
  position: absolute;
  right: 0.9rem;
  /* border-bottom: 0.5px solid #c4c4c4; */
  margin-bottom: 0.5em;
}

.itemName {
  font-size: 0.875rem;
  font-family: Poppins;
  margin-top: 0.625rem;
}

.ELFooter {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0.5em;
}

.ELBtn {
  color: white;
  padding: 0.2em 0.3em;
  width: 28%;
  border-radius: 20px;
  background-color: #67833e;
  font-size: 0.9em;
  text-align: center;
}

#image-container {
  grid-column: 1 / span 4;
  grid-row: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* z-index: 1; */
  /* opacity: 1; */
}

#image-container > img {
  /* position: absolute; */
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  border: 3px solid white;
  /* box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  /* z-index: 3; */
  object-fit: cover;
  z-index: 1;
  border: 0.25rem solid white;
}

.content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.description-text {
  font-size: 0.725rem;
}

.description-container {
  text-align: center;
  margin-top: 1rem;
}

.ingredient-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

@media screen and (max-device-width: 500px) {
  .menu-item-ELWrappers {
    width: 100%;
    left: 0px;
  }
}

@media screen and (min-device-width: 500px) {
  .menu-item-ELWrappers {
    width: 360px;
  }
}

.CPWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 20;
}

#modal-background {
  position: absolute;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 15;
  width: 100vw;
  background-color: white;
  opacity: 0.4;
  filter: brightness(50%);
}

.CPContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* width: 82%; */
  opacity: 1;
  background-color: white;
  border-radius: 5px;
  position: relative;
  padding: 0.7rem 0.9rem;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.8em;
  word-spacing: 5px;
  z-index: 20;
}

.conf-accept {
  color: #67833e;
  margin-left: 5%;
  cursor: pointer;
  font-size: 12px;
}

.conf-cancel {
  color: #7a7a7a;
  cursor: pointer;
  font-size: 12px;
}

.conf-question {
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 3%;
}

@media screen and (max-device-width: 500px) {
  .CPContainer {
    width: 80%;
  }
}

@media screen and (min-device-width: 500px) {
  .CPContainer {
    width: 335px;
  }
}

.price {
  font-weight: 400 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.money-symbol {
  font-size: 0.9375rem !important;
  /* position: relative; */
  font-weight: 300 !important;
  margin-right: 0.6px;
  display: inline;
  margin-right: 2px;
  font-family: none;
  /* line-height: 9.5px; */
  top: 0px;
  /* margin-left: 10px; */
}

.offer-money-symbol{
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  margin-right: 0.6px;
  display: inline;
  margin-right: 2px;
  font-family: none;
  top: 0px;
}

.price-container {
  /* width: 40px; */
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
  justify-content: center;
}

.offer-price-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #67833e;
  text-decoration: line-through;
}

.price span {
  top: 0px;
  /* line-height: 10px; */
  font-size: 0.9375rem;
}

.big span {
  font-size: 1.125rem;
}


.money-sup {
  font-family: Poppins;
  font-size: 0.7rem !important;
  position: relative;
  top: 0px;
  font-weight: 300 !important;
  top: -2px;
}

