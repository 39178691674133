.menu-item-ELWrappers {
  position: fixed;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 16;
}

#modals-background {
  position: fixed !important;
  height: 163vh;
  left: 0;
  top: 0;
  z-index: 16;
  width: 100%;
  background-color: white;
  opacity: 0.4;
  filter: brightness(0%);
}

.menu-item-ELContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  opacity: 1;
  background-color: white;
  border-radius: 5px;
  top: 20%;
  position: relative;
  padding: 0.9rem 0.9rem;
  font-family: Poppins;
  font-size: 0.8em;
}

.ItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  padding-bottom: 0.5em;
  position: absolute;
  right: 0.9rem;
  /* border-bottom: 0.5px solid #c4c4c4; */
  margin-bottom: 0.5em;
}

.itemName {
  font-size: 0.875rem;
  font-family: Poppins;
  margin-top: 0.625rem;
}

.ELFooter {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0.5em;
}

.ELBtn {
  color: white;
  padding: 0.2em 0.3em;
  width: 28%;
  border-radius: 20px;
  background-color: #67833e;
  font-size: 0.9em;
  text-align: center;
}

#image-container {
  grid-column: 1 / span 4;
  grid-row: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* z-index: 1; */
  /* opacity: 1; */
}

#image-container > img {
  /* position: absolute; */
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  border: 3px solid white;
  /* box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  /* z-index: 3; */
  object-fit: cover;
  z-index: 1;
  border: 0.25rem solid white;
}

.content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.description-text {
  font-size: 0.725rem;
}

.description-container {
  text-align: center;
  margin-top: 1rem;
}

.ingredient-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

@media screen and (max-device-width: 500px) {
  .menu-item-ELWrappers {
    width: 100%;
    left: 0px;
  }
}

@media screen and (min-device-width: 500px) {
  .menu-item-ELWrappers {
    width: 360px;
  }
}
