body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  scrollbar-width: none;
  -ms-overflow-style: none;
  user-select: none; 
  cursor: default;
}
/* body::-webkit-scrollbar {
  display: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input[type="tel"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.Toastify__toast--success {
  background-color: #67833e !important;
  font-family: Poppins;
}

/**
 Adds Poppins Regular,Light,Medium   
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
